import { Link } from "gatsby"
import React, { useState } from "react"
import Slider from "react-slick"
import Layout from "../components/layout"
import Seo from "../components/seo"
import usinagem from "../images/usinagem-cnc.jpg"
import certificado from "../images/certificado-iso.png"
import iso from "../images/iso.png"
import weg from "../images/weg.png"
import jn from "../images/jn.png"
import winter from "../images/winter.png"
import zanotti from "../images/zanotti.png"
import walbert from "../images/walbert.png"
import union from "../images/union.png"
import vama from "../images/vama.png"
import triplast from "../images/triplast.png"
import whb from "../images/whb.png"
import mafra from "../images/mafra.png"
import mm from "../images/mm.png"
import pks from "../images/pks.png"
import gtf from "../images/gtf.png"
import herweg from "../images/herweg.png"
import tupy from "../images/tupy.png"
import capaHeader from "../images/capaHeader.png"
import first from "../images/1.png"
import second from "../images/2.png"
import third from "../images/3.png"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Modal, Tooltip } from "antd"
import MaquinaModal from "../components/maquinaModal"

const IndexPage = () => {
  const [visibleCertificate, setvVisibleCertificate] = useState(false)
  const [position, setPosition] = useState()

  if (typeof window !== "undefined")
    window.onscroll = () => {
      visibleCertificate && window.scrollTo(0, parseInt(position || "0"))
    }

  var settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplaySpeed: 3000,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          autoplaySpeed: 3000,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplaySpeed: 3000,
          slidesToShow: 1,

          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <Layout
      titlePage="Bem-vindo à Inovação"
      carrossel
      partners
      contactButton
      titleMargin="37"
    >
      <Seo title="Home" />
      <div className=" mt-16 grid grid-cols-1 md:grid-cols-2 gap-10">
        <img src={usinagem} />
        <div>
          <div
            style={{ fontFamily: "Source Sans Pro" }}
            className="text-justify text-xl my-6"
          >
            Nós somos a <strong>Inovação Usinagem e Modelação</strong>, que atua
            desde 2010 no mercado apresentando sobretudo confiança, competência
            e credibilidade em nossos trabalhos.
          </div>
          <Link to="/empresa">
            <h4>Clique para saber mais sobre nós.</h4>
          </Link>
        </div>
      </div>
      <h2 className="text-center text-2xl font-semibold my-16">
        Além da usinagem, também trabalhamos com outros produtos
      </h2>
      <center>
        <Link to="/produtos">
          <div className="grid grid-cols-3 gap-0 md:w-1/2">
            <img src={first} />
            <img src={second} />
            <img src={third} />
          </div>
        </Link>
        <Link
          to="/produtos"
          className="col-span-1 text-xl text-center self-center font-semibold"
          style={{ fontFamily: "Source Sans Pro", color: "rgb(0, 8, 51)" }}
        >
          Clique para saber mais
        </Link>
      </center>
      <div
        className="full-width md:px-32 px-12 py-10 mt-16"
        style={{
          // backgroundColor: "rgb(0,8,54)",
          backgroundImage: `url(${capaHeader})`,

          fontFamily: "Source Sans Pro",
          color: "white",
        }}
      >
        <div className="text-center text-3xl font-semibold mb-8">
          Sinonimo de qualidade
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4">
          <img className="h-32 md:ml-8 ml-16" src={iso} />
          <p className="col-span-3 md:text-left text-center font-bold mt-6">
            Certificada pela ISO 9001 e atenta às exigências do mercado global,
            a Inovação Usinagem LTDA investe em inovações tecnológicas, com
            maquinários de última geração e promove o desenvolvimento de seus
            colaboradores.
          </p>
          <Link
            className="text-center md:text-left"
            to="../images?certificado-iso.png"
          >
            Clique aqui para ver o nosso certificado.
          </Link>
        </div>
      </div>
      <h2 className="text-center mb-10 partners">Nossos parceiros</h2>
      <center>
        <Slider {...settings}>
          <img className="md:p-10 p-6" src={jn} />
          <img className="md:p-10 p-6" src={tupy} />
          <img className="md:p-10 p-6" src={weg} />
          <img className="md:p-10 p-6" src={winter} />
          <img className="md:p-10 p-6" src={zanotti} />
          <img className="md:p-10 p-6" src={walbert} />
          <img className="md:p-10 p-6" src={union} />
          <img className="md:p-10 p-6" src={mm} />
          <img className="md:p-10 p-6" src={vama} />
          <img className="md:p-10 p-6" src={triplast} />
          <img className="md:p-10 p-6" src={whb} />
          <img className="md:p-10 p-6" src={mafra} />
          <img className="md:p-10 p-6" src={pks} />
          <img className="md:p-10 p-6" src={gtf} />
          <img className="md:p-10 p-6" src={herweg} />
        </Slider>
      </center>
    </Layout>
  )
}

export default IndexPage
